import React, { useState, useEffect } from 'react'
import PostHeader from '../components/Post/PostHeader'
import PostPagination from '../components/Post/PostPagination'
import CommentList from '../components/Comment/CommentList'
import PageSeo from '../components/Seo/PageSeo'
import { graphql } from 'gatsby'
import { DiscussionEmbed } from 'disqus-react'
import './share-btn.css'
import Newsletter from '../components/Newsletter'

const Post = ({ location, data: { markdownRemark: post }, pageContext }) => {
  const [showShareBtn, setShowShareBtn] = useState(false)

  useEffect(() => {
    setTimeout(() => setShowShareBtn(true), 1000)
  }, [])

  return (
    <>
      <PageSeo page={post} path={location.pathname} />

      <article itemScope="" itemType="http://schema.org/BlogPosting">
        <div className="container pt-16 px-3 max-w-3xl">
          <PostHeader post={post} />

          <div
            className="post-content text-lg"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          {/** social share buttons  */}
          <div className="my-6 py-6 flex flex-col flex-wrap border-t border-indigo-100">
            <div>
              <h3 className="share-this-header">
                {showShareBtn
                  ? 'إن أعجبتك التدوينة فشاركها على مواقع التواصل.'
                  : null}
              </h3>
            </div>
            <div class="s9-widget-wrapper"></div>
          </div>
          <Newsletter />
        </div>

        <div className="container px-3 max-w-6xl">
          <PostPagination pageContext={pageContext} />
        </div>

        <div className="bg-gray-100">
          <div className="container px-3 max-w-3xl py-16">
            <CommentList post={post} />
            <DiscussionEmbed
              shortname={'watheq-show'}
              config={(post.slug, post.title)}
            />
          </div>
        </div>
      </article>
    </>
  )
}

export default Post

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(
      frontmatter: { layout: { eq: "post" } }
      fields: { slug: { eq: $slug } }
    ) {
      html
      timeToRead
      excerpt(pruneLength: 250)
      fields {
        slug
        lang
        datePublished: date(formatString: "YYYY-MM-DD")
        date: date(formatString: "DD-MM-YYYY", locale: "en")
      }
      frontmatter {
        layout
        title
        category
      }
    }
  }
`
