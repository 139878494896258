import React from 'react'

const Newsletter = () => (
  // <!-- Begin Mailchimp Signup Form -->
  <div className="bg-gray-100 p-8 mb-6 rounded-lg">
    <h3 className="mt-2 mb-4">
      أدخل بريدك الإلكتروني واشترك في القائمة البريدية كي لا تفوتك أي تدوينة
      جديدة
    </h3>
    <form
      action="https://xyz.us19.list-manage.com/subscribe/post?u=e89e9cac4b887c5ca51a7bfa8&amp;id=d4d17540ed"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      novalidate
    >
      <div class="md:items-center mb-6">
        <div class="flex flex-col md:flex-row">
          <input
            class="appearance-none border-2 border-gray-200 rounded w-full md:w-2/3 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 text-left"
            placeholder="example@email.com"
            type="email"
            name="EMAIL"
            id="mce-EMAIL"
            required
            style={{ direction: 'lrt' }}
          />
          {/**  <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
          <div
            style={{
              position: 'absolute',
              right: '-5000px;',
              ariaHidden: 'true',
            }}
          >
            <input
              type="text"
              name="b_e89e9cac4b887c5ca51a7bfa8_d4d17540ed"
              tabindex="-1"
              value=""
              style={{
                background: 'transparent',
                outline: 'transparent',
                visibility: 'hidden',
              }}
            />
          </div>
          <button
            class="bg-indigo-500 hover:bg-indigo-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded mt-2 md:mt-0"
            type="submit"
            name="subscribe"
            id="mc-embedded-subscribe"
          >
            اشترك الآن{' '}
          </button>
        </div>
      </div>
    </form>
  </div>
)

export default Newsletter
