import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CommentList = () => {
  return (
    <>
      <h2 id="comments" className="text-3xl">
        <FontAwesomeIcon icon="comments" className="mr-4" /> التعليقات
      </h2>
    </>
  )
}

export default CommentList
