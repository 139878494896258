import React from 'react'
import { formatReadingTime } from '../../utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'

const PostHeader = ({ post, allCommentsYaml }) => {
  return (
    <header className="pb-4 mb-8 border-b">
      <Link to="/categories" className="btn btn--lightest btn--sm">
        {post.frontmatter.category}
      </Link>

      <h1 className="my-2 text-black leading-tight">
        {post.frontmatter.title}
      </h1>

      <ul className="mb-4 text-xl text-indigo-500">
        <li className="block sm:inline-block ml-3">
          <time dateTime={post.fields.datePublished}>
            <FontAwesomeIcon
              icon={['far', 'calendar-alt']}
              className="mr-1 text-indigo-200"
            />{' '}
            {post.fields.date}
          </time>
        </li>

        <li className="block sm:inline-block">
          <FontAwesomeIcon
            icon={['far', 'clock']}
            className="mr-1 text-indigo-200"
          />{' '}
          {formatReadingTime(post.timeToRead)}
        </li>
      </ul>
    </header>
  )
}

export default PostHeader
