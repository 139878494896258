export function formatReadingTime(minutes) {
  if (minutes === 1) {
    return `دقيقة واحدة للقراءة`
  } else if (minutes === 2) {
    return `دقيقتان للقراءة`
  } else if (minutes >= 3 && minutes < 11) {
    return `${minutes} دقائق للقراءة`
  } else {
    return `${minutes} دقيقة للقراءة`
  }
}
